import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import auth from "../utils/auth";
import { useAuth } from "../App";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { message, Space } from "antd";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const uiInstanceRef = useRef(null);
  const firebaseuiElement = useRef(null);

  const uiConfig = useMemo(
    () => ({
      signInFlow: "popup",
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod:
            firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          requireDisplayName: false,
          // disableSignUp: {
          //   status: true,
          // },
          signInSuccessUrl: "/view",
        },
      ],
      signInSuccessUrl: "/view",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      tosUrl: null,
      privacyPolicyUrl: null,
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          console.log("Sign in success", authResult);
          const redirectTo = location.state?.from?.pathname || "/view";
          navigate(redirectTo, { replace: true });
          return false;
        },
        signInFailure: (error) => {
          console.error("Sign in failure:", error);
          message.error(t("login.signInError"));
        },
        uiShown: () => {
          setIsLoading(false);
          console.log("UI shown");
        },
      },
    }),
    [navigate, location, t]
  );

  useEffect(() => {
    console.log("LoginPage mount - Starting initialization");

    if (user) {
      console.log("User exists, redirecting");
      const redirectTo = location.state?.from?.pathname || "/view";
      navigate(redirectTo, { replace: true });
      return;
    }

    const initializeUI = async () => {
      try {
        console.log("Initializing Firebase UI");

        // If we already have an instance in our ref, use it
        if (!uiInstanceRef.current) {
          // Try to get existing instance
          const existingUI = firebaseui.auth.AuthUI.getInstance();
          if (existingUI) {
            uiInstanceRef.current = existingUI;
          } else {
            // Create new instance if none exists
            uiInstanceRef.current = new firebaseui.auth.AuthUI(auth);
          }
        }

        if (firebaseuiElement.current) {
          console.log("Starting UI");
          await uiInstanceRef.current.start(
            firebaseuiElement.current,
            uiConfig
          );
          console.log("UI initialization complete");
        }
      } catch (error) {
        console.error("UI initialization error:", error);
        message.error(t("login.initializationError"));
      }
    };

    // Delay initialization slightly to ensure DOM is ready
    const timer = setTimeout(initializeUI, 100);

    return () => {
      clearTimeout(timer);
      // Don't delete the instance on cleanup, just reset the UI
      if (uiInstanceRef.current) {
        console.log("Cleanup: Resetting UI");
        uiInstanceRef.current.reset();
      }
    };
  }, [user, navigate, location, uiConfig, t]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <img
        src={ZengradeLogo}
        alt={t("login.logo")}
        style={{
          width: "200px",
          marginBottom: "30px",
        }}
      />

      <div
        style={{
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "white",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <div ref={firebaseuiElement} style={{ minHeight: "300px" }} />
        {isLoading && (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {t("login.loading")}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
